/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';

const CarouselPc = ({bannerDataPc}) => {


  return (
  <section style={{marginTop: 68}} className="carouselPc">
    {bannerDataPc.length == 1 ? (
      
      bannerDataPc.map((bp, index) => (
       
        <img key={index}
          className="d-block w-100"
          src={bp.gambarBanner}
          alt="First slide"
        />
     
     ))

    ) : (
            <Carousel fade>
                
              {bannerDataPc.map((bp, index) => (
                <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={bp.gambarBanner}
                  alt="First slide"
                />
              </Carousel.Item>
              
              ))}
          
          </Carousel>
 
    )}
   

  </section>

  )
}

export default CarouselPc