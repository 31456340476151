import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRouter } from 'next/router';

const ProductCarousel = ({productData}) => {


  const [show, setShow] = useState(false);

  const [name,setname] = useState('');
  const [picture,setpicture] = useState('');
  const [deskripsi,setdeskripsi] = useState('');
  const [harga,setharga] = useState('');
  const [satuanBeli,setsatuanBeli] = useState('');
  const [qtyProduk,setqtyProduk] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (product) => {
    setShow(true);
    setname(product.name)
  setpicture(product.productPicture)
  setharga(product.harga)
  setdeskripsi(product.deskripsiProduct)
  setsatuanBeli(product.satuanBeli);
  setqtyProduk(product.qtyProduk);

  

  }


    const router = useRouter();
    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: "linear",
        infinite: true,
        responsive: [
          
          {
            breakpoint: 1327,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              cssEase: "linear",
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              cssEase: "linear",
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              infinite: true,
            }
          }
        ]
      };
  return (
    <>
    <div className="row justify-content-center">

<Slider {...settings}>

  {productData.map((product) => (
    <div className="card col-lg-4 produkPointer" key={product._id} onClick={router.pathname !== '/produk' ? handleClose  : () => handleShow(product)}>
    <img src={product.productPicture} className="card-img-top" alt="..." />
    <div className="card-body text-white text-center">
        <h5 className={router.pathname !== '/produk' ?'card-title' : 'card-title text-black'}>{product.name}</h5>
    </div>
</div>
  ))}


    </Slider>


   
      
  
    </div>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><p className='text-black'>{name}</p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center><img src={picture} alt="" width={300} /></center>
          <table className='text-black mb-2'>
            <tbody>
            <tr>
              <td>
              Ukuran
              </td>
              <td> : </td>
              <td>
                {name}
              </td>
            </tr>

            <tr>
              <td>
              Satuan Beli
              </td>
              <td> : </td>
              <td>
                {satuanBeli}
              </td>
            </tr>

            <tr>
              <td>
              Quantity per beli
              </td>
              <td> : </td>
              <td>
                {qtyProduk}
              </td>
            </tr>
            </tbody>
          </table>

          <p className='text-black'>{deskripsi}</p>




        </Modal.Body>
        <Modal.Footer>
          <center>
          <Button variant="primary" onClick={handleClose}>
            Tutup
          </Button>
          </center>
        </Modal.Footer>
      </Modal>
    
    </>
  )
}

export default ProductCarousel