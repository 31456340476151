import React from 'react'
import Head from "next/head"
import Navigation from './big/Navigation'
import Footer from './big/Footer'

const Layout = ({titlePage, children, titleData = {},dataOrmas = {}}) => {
  return (
    <>
        <Head>
            <title>{titlePage && (`${titlePage} - ${titleData?.nameProduk}`)}</title>
            <link rel="icon" href={titleData.titleLogo} />
            <meta name="description" content={titleData?.deskripsiSumber}></meta>
            <meta name="author" content="Muchamad Aqmal Hidayat" />
            <meta name="keywords" content="air almasoem, PT. Muawana Al Masoem, air al masoem, air al ma'soem, al masoem, air minum al masoem, airalmasoem, ai almaseom,PT. Muawanah Al Ma'soem, Air Al Masoem, pesan air al maseom, loker al masoem,pt muawanah al ma'soem,pt masoem,pt almasoem,pt al ma'soem, gaji di pt al ma'soem, air mineral al masoem" />
            <meta property="og:url" content="https://www.airalmasoem.com/" />
            <meta property="og:image" content={titleData.titleLogo} />
            <meta property="og:title" content={titleData?.nameProduk} />
           <meta charSet="utf-8"></meta>
        </Head>

        <main>
          <Navigation titleData={titleData}/>
          
            {children}
          <Footer titleData={titleData} dataOrmas={dataOrmas}/>
        </main>
    </>
  )
}



export default Layout