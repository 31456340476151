import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';

const CarouselMobile = ({bannerDataMobile}) => {
  return (
    <section style={{marginTop: 68}} className="carouselMobile">
    {bannerDataMobile.length == 1 ? (
      
      bannerDataMobile.map((bp, index) => (
       
        <img key={index}
          className="d-block w-100"
          src={bp.gambarBanner}
          alt="First slide"
        />
     
     ))

    ) : (
            <Carousel fade>
                
              {bannerDataMobile.map((bp, index) => (
                <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={bp.gambarBanner}
                  alt="First slide"
                />
              </Carousel.Item>
              
              ))}
          
          </Carousel>
 
    )}
  </section>
  )
}

export default CarouselMobile