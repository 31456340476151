/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image'
import React from 'react'

const Footer = ({titleData,dataOrmas}) => {
    
  return (
    <section id='footer'>
        <footer className='bg-secondary pt-5 pb-3'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-white">
                        <h2 className='fw-bold'>{titleData.judulFooter}</h2>
                        <p className='fs-6' style={{marginBottom : "0.2rem"}}>
                        {titleData.alamat}
                        </p >
                        <p className='fs-6' style={{marginBottom : "0.2rem"}}>
                        Email : {titleData.email}
                        </p>
                        <p className='fs-6' style={{marginBottom : "0.2rem"}}>
                            Telp / WA : {titleData.wa}
                        </p>

                        <p className='fs-6 mt-2 text-muted' style={{marginBottom : "0.2rem"}}>
                            @ 2022 - {titleData.nameProduk} <br />
                            {titleData.end}
                        </p>
                    </div>
                    <div className="col-md-6">
                    <h2 className='fw-bold text-light'>{titleData.nameProduk}</h2>

                        {dataOrmas.map((ormas) => (
                            <div className='d-inline me-2' key={ormas._id}>
                            <Image src={ormas.pictureRelasi} width="60" height="60" alt=""/>
                            </div>
                        ))}
                        



                       
                    </div>
                </div>
            </div>
        </footer>
    </section>
  )
}

export default Footer