import React, {useState, useEffect} from 'react'
import Image from "next/image"
import Link from "next/link"
import { useRouter } from 'next/router'

const Navigation = ({titleData}) => {
  const router = useRouter();
  const [conNavbar,setConNavbar] = useState(false);

 

  useEffect(() => {
    function onScroll() {
      if(window.scrollY >= 80) {
        setConNavbar(true)
      } else {
        setConNavbar(false)
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  },[conNavbar])

  return (
    
    
  <nav className={conNavbar ? 'navbar navbar-expand-lg bg-primary navbar-dark navbar-darkk fixed-top' : 'navbar navbar-expand-lg bg-primary navbar-dark fixed-top'}>
    
   
  <div className="container">
    <a className="navbar-brand" href="#">
    <Image src={titleData.titleWeb} alt="" width="106" height="50" />
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarColor01">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
        <Link href="/">
          <a className={router.pathname === '/' ? 'nav-link active' : 'nav-link'} href="#">Beranda
            <span className="visually-hidden">(current)</span>
          </a>
        </Link>
        </li>
        <li className="nav-item">
            <Link href="/produk">
              <a className={router.pathname === '/produk' ? 'nav-link active' : 'nav-link'} >Produk</a>
            </Link>
        </li>
        <li className="nav-item">
        <Link href="/about">
              <a className={router.pathname === '/about' ? 'nav-link active' : 'nav-link'} >Tentang Kami</a>
            </Link>
        </li>

        <li className="nav-item">
          <Link href="/#contact">
              <a className={router.pathname === '/#contact' ? 'nav-link active' : 'nav-link'} >kontak</a>
          </Link>
        </li>

        <li className="nav-item me-3">
          <Link href="/artikel">
              <a className={router.pathname === '/artikel' ? 'nav-link active' : 'nav-link'} >Artikel</a>
          </Link>
        </li>

       
        
       
      </ul>
      
      <Link href="/#contact">
        <a className="btn btn-warning my-2 my-sm-0 text-black" type="submit">Pesan Sekarang!!</a>
        </Link>
    </div>
  </div>
</nav>
  )
}

export default Navigation